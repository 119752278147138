/*--------------------------------------------------------------
LOGO  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Toogle negative
--------------------------------------------------------------*/



/*--------------------------------------------------------------
	Toogle negative
--------------------------------------------------------------*/
var logo = {
	l: $$.id('logo'),
	neg: function(){
		this.l.className = 'neg';
	},
	reset: function(){
		this.l.className = '';
	}
};