/*--------------------------------------------------------------
FOURNISSEURS & PARTENAIRES  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Init
--------------------------------------------------------------*/



/*--------------------------------------------------------------
	Init
--------------------------------------------------------------*/
init.partners = function(){
	mouse.start();
};