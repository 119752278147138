/*--------------------------------------------------------------
NAV  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Nav
- Scroll
- Arrows
- Init
--------------------------------------------------------------*/



/*--------------------------------------------------------------
	Nav
--------------------------------------------------------------*/
var nav = {
	int: 0,
	enabled: true,
	init: function(){
		window.addEventListener('mousewheel', this.scroll);
		window.addEventListener('DOMMouseScroll', this.scroll);
		window.addEventListener('keydown', this.arrows);
	},
	enable: function(){
		this.enabled = true;
	},
	unable: function(){
		this.enabled = false;
	},
	move: function(index){
		if(!this.enabled) return;
		this.int = 0;
		if(page.index == 1)
			this.besoins(index);
		else if(index+1 && page.links[index])
			page.go2(page.links[index].href);
	},
	besoins: function(index){
		if(besoins.bol || page.bol) return;
		if(besoins.index+index == 0)
			page.go2(page.links[index].href);
		else if(besoins.index+index > 4)
			page.go2(page.links[index].href);
		else
			besoins.move(index-1);
	}
};



/*--------------------------------------------------------------
	Scroll
--------------------------------------------------------------*/
nav.scroll = function(e){
	var delta = e.wheelDelta || -e.detail;
	delta = delta > 0 ? -1 : 1;
	if(page.bol) return;
	nav.int += delta;
	if(nav.int > 10){
		nav.move(page.index+1);
	}else if(nav.int < -10){
		nav.move(page.index-1);
	}
};



/*--------------------------------------------------------------
	Arrows
--------------------------------------------------------------*/
nav.arrows = function(e){
	if(e.keyCode == 40)
		nav.move(page.index+1);
	else if(e.keyCode == 38)
		nav.move(page.index-1);
};



/*--------------------------------------------------------------
	Init
--------------------------------------------------------------*/
nav.init();
