/*--------------------------------------------------------------
AJAX  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Page
--------------------------------------------------------------*/



/*--------------------------------------------------------------
	Page
--------------------------------------------------------------*/
var page = {
	bol: false,
	links: $$.all('#sidebar a'),
	init: function(){
		page.count();
		page.bind();
		window.onpopstate = page.change;
	},
	href: function(e){
		e.preventDefault();
		var el = e.target;
		var i = 3;
		while(i--){
			if(el.nodeName != 'A') el = el.parentNode;
		}
		if(window.location.href != el.href) page.go2(el.href);
		else menu.close();
	},
	go2: function(href){
		page.oldIndex = page.index;
		history.pushState(null, null, href);
		this.change();
	},
	change: function(){
		if(page.bol) return;
		page.bol = true;
		page.url = window.location.href;
		page.call();
	},
	call: function(){
		page.destroy();
		$$.ajax.get(page.url).then(function(res){
			page.count();
			page.create(res);
			page.delete();
			page.bind();
		});
	},
	destroy: function(){
		menu.close();
		logo.pageNeg = false;
		var id = $$.first('.volet').id;
		id = id.replace(/-/g, '_');
		if(id in destroy) destroy[id]();
	},
	create: function(data){
		var raw = document.createElement('div');
		raw.innerHTML = data;
		this.new = raw.get('.volet');
		this.new.className = 'volet in';
		document.body.className = this.index > this.oldIndex ? 'up' : 'down';
		document.title = raw.get('title').textContent;
		document.body.appendChild(this.new);
	},
	delete: function(){
		var old = $$.first('.volet');
		old.className = 'volet out';
		$$.timeout(500, function(){
			document.body.scrollTo(0, 0);
		});
		$$.timeout(1000, function(){
			document.body.removeChild(old);
			page.bol = false;
			page.new.className = 'volet';
			document.body.className = '';
		});
	},
	bind: function(dom){
		var id = this.new ? this.new.id : $$.first('.volet').id;
		id = id.replace(/-/g, '_');
		if(id in init) init[id]();
		nav.enable();
		titre.init();
		if(logo.pageNeg) logo.neg();
		else logo.reset();
	},
	count: function(){
		var url = /[a-z-]+$/.exec(window.location.href) || '';
		var urls = [];
		for(var i in this.links){
			urls.push(this.links[i].pathname);
		}
		this.index = urls.indexOf('/' + url);
		menu.set();
		sidebar.set();
	}
};
$$.ready(page.init);
