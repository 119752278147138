/*--------------------------------------------------------------
NOTRE DUO  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Duo
- Init
--------------------------------------------------------------*/



/*--------------------------------------------------------------
	Duo
--------------------------------------------------------------*/
var duo = {
	init: function(){
		this.bios = $$.all('.bio');
		this.figs = $$.all('figure');
	},
	select: function(i){
		var o = (i-1)*-1;

		// this.figs[i].style.transition = '.3s';
		// this.figs[o].style.transition = '.3s';

		this.bios[i].classList.add('visible');
		this.bios[o].classList.remove('visible');

		this.figs[i].className = 'sharp';
		this.figs[o].className = 'blur';

		this.figs[i].setAttribute('data-mouse', .5);
		this.figs[o].setAttribute('data-mouse', .25);

		// $$.timeout(100, function(){
		// 	duo.figs[i].style.transition = 'none';
		// 	duo.figs[o].style.transition = 'none';
		// });
	}
};



/*--------------------------------------------------------------
	Init
--------------------------------------------------------------*/
init.duo = function(){
	duo.init();
	menu.bleu();
	mouse.start();
};
