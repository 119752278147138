/*--------------------------------------------------------------
TITRE  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Titre
- Init
--------------------------------------------------------------*/



/*--------------------------------------------------------------
	Titre
--------------------------------------------------------------*/
var titre = {
	init: function(){
		this.spans = $$.all('h1 span');
		var delay = document.body.className == 'first' ? 3500 : 500;
		$$.timeout(delay, function(){
			titre.show();
		});
	},
	show: function(){
		var i = this.spans.length;
		while(i--){
			this.spans[i].style.transitionDelay = this.spans[i].style.webkitTransitionDelay = i/40 + 's';
			this.spans[i].style.transform = this.spans[i].style.webkitTransform = 'none';
			this.spans[i].style.opacity = 1;
		}
	}
};