/*--------------------------------------------------------------
AVEC VOUS  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Vous
- Desktop
- Mobile
- Init
--------------------------------------------------------------*/



/*--------------------------------------------------------------
	Vous
--------------------------------------------------------------*/
var vous = {
	init: function(){
		this.bol = false;
		this.slides = $$.all('.tile');
		this.imgs = $$.all('.tiles>img');
		this.wrapper = $$.first('.tiles');
		this.tilesWrapper = $$.first('.tilesWrapper');
		this.bullets = $$.all('#bullets div');
		this.mobile.index = 0;
	}
};



/*--------------------------------------------------------------
	Desktop
--------------------------------------------------------------*/
vous.desktop = {
	move: function(el){
		if(vous.bol || window.innerWidth < 700) return;
		this.index = parseInt(el.className.substr(-1));
		this.index -= 2;
		if(!this.index) return;
		this.add();
	},
	add: function(){
		vous.desktop.bol = true;
		if(this.index == 1){
			var clone = $$.first('.p1').cloneNode(true);
			clone.className = 'tile p4';
			vous.wrapper.className = 'tiles r';
		}else if(this.index == -1){
			var clone = $$.first('.p3').cloneNode(true);
			clone.className = 'tile p0';
			vous.wrapper.className = 'tiles l';
		}
		vous.tilesWrapper.appendChild(clone);
		$$.timeout(1, function(){
			vous.desktop.change.tiles();
			vous.desktop.change.imgs();
		});
	},
	change: {
		tiles: function(){
			var tiles = $$.all('.tile');
			var i = tiles.length;
			while(i--){
				var old = parseInt(tiles[i].className.substr(-1));
				tiles[i].className = 'tile p' + (old - vous.desktop.index);
			}
		},
		imgs: function(){
			var i = vous.imgs.length;
			while(i--){
				var old = parseInt(vous.imgs[i].className.substr(-1));
				old = (old - vous.desktop.index + 3)%3;
				vous.imgs[i].className = 'i' + old;
			}
			$$.timeout(300, vous.desktop.delete);
		}
	},
	delete: function(){
		var old = $$.first('.p0') || $$.first('.p4');
		old.delete();
		vous.wrapper.className = 'tiles';
		vous.desktop.bol = false;
	}
};



/*--------------------------------------------------------------
	Swipe
--------------------------------------------------------------*/
vous.mobile = {
	go2: function(nbr){
		if(vous.bol || window.innerWidth > 700) return;
		this.index = nbr;
		var i = vous.slides.length;
		while(i--){
			if(i<nbr){
				vous.slides[i].className = 'tile left';
				vous.imgs[i].className = 'left';
				vous.bullets[i].className = '';
			}else if(i>nbr){
				vous.slides[i].className = 'tile right';
				vous.imgs[i].className = 'right';
				vous.bullets[i].className = '';
			}else{
				vous.slides[i].className = 'tile';
				vous.imgs[i].className = '';
				vous.bullets[i].className = 'on';
			}
		}
		$$.timeout(600, function(){
			vous.bol = false;
		});
	},
	start: function(e){
		this.offset = e.touches[0].pageX;
	},
	change: function(e){
		var x = e.touches[0].pageX - this.offset;
		if(x>100 && this.index-1 > -1){
			this.go2(this.index-1);
			vous.bol = true;
		}else if(x<-100 && this.index+1 < vous.slides.length){
			this.go2(this.index+1);
			vous.bol = true;
		}
	}
};



/*--------------------------------------------------------------
	Init
--------------------------------------------------------------*/
init.vous = function(){
	vous.init();
	mouse.start();
};
