/*--------------------------------------------------------------
VOS BESOINS  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Besoins
- Init
--------------------------------------------------------------*/



/*--------------------------------------------------------------
	Besoins
--------------------------------------------------------------*/
var besoins = {
	fake: document.createElement('div'),
	init: function(){
		this.index = -1;
		this.bol = false;
		this.slides = $$.all('.slide');
		this.arrow = $$.first('.arrow');
		this.next = $$.first('#besoins .next');
		this.bg.fond = $$.first('.fond');
		$$.timeout(0, function(){
			besoins.move(1);
		});
	},
	move: function(dir){
		if(this.bol) return;
		this.bol = true;
		pauseRender = true;

		this.index += dir;

		var prev = this.slides[this.index-1] || this.fake;
		var curr = this.slides[this.index] || this.fake;
		var next = this.slides[this.index+1] || this.fake;

		prev.className = 'slide move l';
		curr.className = 'slide move';
		next.className = 'slide move r';

		this.bg.close();

		$$.timeout(1100, function(){
			curr.className = 'slide';
		});

		this.mask();

		$$.timeout(1500, function(){
			besoins.bol = false;
			besoins.bg.change(besoins.index+1);
			besoins.slides[besoins.index].className = 'slide';
			pauseRender = false;
		});
	},
	mask: function(){
		if(this.index == 3){
			this.arrow.className = 'arrow hidden';
			this.next.className = 'next neg';
		}else{
			this.arrow.className = 'arrow';
			this.next.className = 'next neg hidden';
		}
	},
	bg: {
		close: function(){
			this.fond.classList.add('closed');
		},
		change: function(i){
			this.fond.className = 'fond p'+i;
		}
	}
}



/*--------------------------------------------------------------
	Init
--------------------------------------------------------------*/
init.besoins = function(){
	logo.pageNeg = true;
	besoins.init();
	mouse.start();
};
