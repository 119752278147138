/*--------------------------------------------------------------
SIDEBAR  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Sidebar
--------------------------------------------------------------*/



/*--------------------------------------------------------------
	Sidebar
--------------------------------------------------------------*/
var sidebar = {
	s: $$.id('sidebar'),
	links: $$.all('#sidebar a'),
	set: function(){
		var i = this.links.length;
		while(i--){
			this.links[i].className = i == page.index ? 'current' : '';
		}
	}
};