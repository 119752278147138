/*--------------------------------------------------------------
TOUCHSCROLL  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- touchScroll
--------------------------------------------------------------*/



/*--------------------------------------------------------------
	Scroll
--------------------------------------------------------------*/
var touchScroll = {
	detect: function(){
		this.passive = false;
		try{
			var opts = Object.defineProperty({}, 'passive', {
				get: function(){
					touchScroll.passive = true;
				}
			});
			window.addEventListener("test", null, opts);
		}catch(e){}
	},
	disable: function(){
		document.body.addEventListener('touchmove', this.prevent, this.passive ? {passive: false} : false);
	},
	enable: function(){
		document.body.removeEventListener('touchmove', this.prevent);
	},
	prevent: function(e){
		e.preventDefault();
	}
};
touchScroll.detect();