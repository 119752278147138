/*--------------------------------------------------------------
COgit brancheNTACT  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Contact
- Init
--------------------------------------------------------------*/



/*--------------------------------------------------------------
	Contact
--------------------------------------------------------------*/
var contact = {
	init: function(){
		this.index = -1;
		this.car = $$.id('carousel');
		this.imgs = this.car.getAll('.img');
		this.copyright();
		$$.timeout(500, function(){
			contact.next();
		});
	},
	next: function(){
		var i = this.imgs.length;
		this.index = (this.index+1+i)%i;
		while(i--){
			this.imgs[i].className = i == this.index ? 'img on' : 'img';
		}
	},
	copyright: function(){
		var today = new Date();
		if(today.getFullYear > 2017)
			$$.id('copyright').textContent = today.getFullYear();
	}
};



/*--------------------------------------------------------------
	Init
--------------------------------------------------------------*/
init.contact = function(){
	contact.init();
	mouse.start();
};