/*--------------------------------------------------------------
MENU  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Menu
--------------------------------------------------------------*/



/*--------------------------------------------------------------
	Menu
--------------------------------------------------------------*/
var menu = {
	m: $$.id('menu'),
	links: $$.all('#menu a'),
	arts: $$.id('arts'),
	toggle: function(){
		if(!this.m.className)
			this.open();
		else
			this.close();
	},
	open: function(){
		this.m.className = 'open';
		nav.unable();
		logo.neg();
		touchScroll.disable();
	},
	close: function(){
		this.m.className = '';
		nav.enable();
		if(logo.pageNeg) logo.neg();
		else logo.reset();
		touchScroll.enable();
	},
	set: function(){
		sidebar.set.call(this);
	},
	bleu: function(){
		this.m.className = 'bleu';
	},
	hover: function(el){
		el = el.parentNode;
		var children = el.parentNode.children;
		var index = Array.prototype.indexOf.call(children, el);
		this.arts.className = 'arts' + (index+1);
	}
};
// menu.toggle()