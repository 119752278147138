/*--------------------------------------------------------------
HOME  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Nous croyons
- Init
- Destroy
--------------------------------------------------------------*/



/*--------------------------------------------------------------
	Nous croyons
--------------------------------------------------------------*/
var croy = {
	init: function(){
		this.index = null;
		this.stopped = false;
		this.untouched = true;
		this.pts = $$.all('.pt');
		this.svg = $$.first('.croyances svg');
		this.imgs = $$.all('.croyances image');
		this.losange = $$.first('.croyances .losange');
		$$.timeout(500, function(){
			croy.select(0);
			croy.autoplay(6000);
		});
	},
	destroy: function () {
		this.stopped = true;
	},
	autoplay: function (delay) {
		this.timeout = $$.timeout(delay, function () {
			if (!croy.untouched) return;
			croy.index = (croy.index + 1) % 3;
			croy.select(croy.index);
			if (!croy.stopped) croy.autoplay(delay);
		});
	},
	select: function(index, btn){
		if (btn) {
			this.untouched = false;
			if (btn.classList.contains('on')) return;
		}
		this.index = index;
		this.reset();
		this.losange.className = 'losange p' + (index+1);
		$$.timeout(300, function(){
			croy.pts[index].classList.add('on');
			croy.imgs[index].setAttribute('class', 'on');
			$$.timeout(200, function(){
				croy.svg.setAttribute('class', '');
			});
		});
	},
	reset: function(){
		this.svg.setAttribute('class', 'out');
		var i = this.pts.length;
		while(i--){
			this.pts[i].classList.remove('on');
			this.imgs[i].setAttribute('class', '');
		}
	}
};



/*--------------------------------------------------------------
	Init
--------------------------------------------------------------*/
init.home = function(){
	mouse.start();
	if(document.body.className != 'first'){
		croy.init();
	}else{
		page.bol = true;
		$$.timeout(3000, function(){
			page.bol = false;
			croy.init();
		});
	}
};



/*--------------------------------------------------------------
-	Destroy
--------------------------------------------------------------*/
destroy.home = function () {
	croy.destroy();
};
