/*--------------------------------------------------------------
MOUSE  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Animation Frame
- Mouse
- Init
--------------------------------------------------------------*/



/*--------------------------------------------------------------
-	Animation Frame
--------------------------------------------------------------*/
window.requestAnimationFrame = window.requestAnimationFrame || window.mozRequestAnimationFrame ||window.webkitRequestAnimationFrame || window.msRequestAnimationFrame;

var lastMouse = {};
var ticking = false;
var pauseRender = false;
function onMove (e) {
	lastMouse.x = e.clientX;
	lastMouse.y = e.clientY;
    requestTick();
}
function requestTick () {
    if(!ticking && !pauseRender) {
        requestAnimationFrame(render);
        ticking = true;
    }
}
function render () {
	var x = (lastMouse.x - mouse.center.x) / 30;
	var y = (lastMouse.y - mouse.center.y) / 30;
	if (mouse.tilts) {
		mouse.tilts.forEach(function (el) {
			if (el.parentNode.classList.contains('move')) return;
			var sign = parseFloat(el.getAttribute('data-tilt'));
			el.style.transform = el.style.webkitTransform = 'rotateX(' + y * sign + 'deg) rotateY(' + x * sign + 'deg)';
		});
	}
	if (mouse.moves) {
		mouse.moves.forEach(function (el) {
			var sign = parseFloat(el.getAttribute('data-mouse'));
			var rotate = el.getAttribute('data-rotate');
			var transform = '';
			if (rotate) {
				transform += 'rotate(' + rotate + ') ';
			}
			if (el.getAttribute('data-axis') == 'x') {
				transform += 'translateX(' + x * sign + 'px)';
			} else {
				transform += 'translate(' + x * sign + 'px, ' + y * sign + 'px)';
			}
			el.style.transform = el.style.webkitTransform = transform;
		});
	}
	ticking = false;
}



/*--------------------------------------------------------------
	Mouse
--------------------------------------------------------------*/
var mouse = {
	init: function(){
		this.resize();
		window.addEventListener('resize', this.resize);
	},
	start: function(){
		this.moves = $$.all('[data-mouse]');
		this.tilts = $$.all('[data-tilt]');
	},
	resize: function(){
		mouse.center = {
			x: window.innerWidth/2,
			y: window.innerHeight/2
		};
	},
};



/*--------------------------------------------------------------
	Init
--------------------------------------------------------------*/
document.addEventListener('DOMContentLoaded', function () {
	if(!isTouch) {
		mouse.init();
		window.addEventListener('mousemove', onMove, false);
	}
});
